import { Button } from '@chakra-ui/button';
import { Center, Heading, VStack } from '@chakra-ui/layout';
import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';

const NotFoundPage = ({ location }) => {
  return (
    <>
      <Layout location={location}>
        <SEO title="404: Not found" />
        <Center h="800px" w="100%" flexDirection="column" bg="primary" color="white">
          <VStack spacing="10px">
            <Heading>404 - Page Not Found</Heading>
            <Link to="/">
              <Button>Go to home</Button>
            </Link>
          </VStack>
        </Center>
      </Layout>
    </>
  );
};

export default NotFoundPage;
